import {
  MutateUserGroupDTO,
  MutateUserToUserGroupDTO,
  QueryUserGroupPaginateEntitiesDTO,
  QueryUserGroupUsersPaginatedEntitiesDTO,
  UserGroupApi,
  UserGroupDTO,
  UserGroupPaginatedResponseDTO,
  UserPaginatedResponseDTO,
} from '@audioeye/auth-client';
import { APP_CONFIG } from 'config';

import { client } from '../client';

const userGroupApi = new UserGroupApi(undefined, APP_CONFIG.auth.serviceUrl, client);

export const getUserGroups = async ({
  filterBy,
  compartmentKey,
  userId,
  cursor,
  orderBy,
  sortDirection,
  pageSize,
}: QueryUserGroupPaginateEntitiesDTO): Promise<UserGroupPaginatedResponseDTO> => {
  const result = await userGroupApi.getUserGroups(
    filterBy,
    compartmentKey,
    userId,
    cursor,
    orderBy,
    sortDirection,
    pageSize,
  );

  return result.data;
};

export const getUsersInUserGroup = async (
  userGroupId: string,
  { pageSize, orderBy, sortDirection, cursor, filterBy }: QueryUserGroupUsersPaginatedEntitiesDTO,
): Promise<UserPaginatedResponseDTO> => {
  const result = await userGroupApi.getUsersInUserGroup(
    userGroupId,
    pageSize,
    orderBy,
    sortDirection,
    cursor,
    filterBy,
  );

  return result.data;
};

export const getUserGroupById = async (userGroupId: string): Promise<UserGroupDTO> => {
  const result = await userGroupApi.getUserGroupById(userGroupId);

  return result.data;
};

export const createUserGroup = async (dto: MutateUserGroupDTO): Promise<UserGroupDTO> => {
  const result = await userGroupApi.createUserGroup(dto);

  return result.data;
};

export const updateUserGroup = async (userGroupId: string, dto: MutateUserGroupDTO): Promise<UserGroupDTO> => {
  const result = await userGroupApi.updateUserGroup(userGroupId, dto);

  return result.data;
};

export const removeUserGroup = async (userGroupId: string): Promise<void> => {
  await userGroupApi.removeUserGroup(userGroupId);
};

export const addUsersToUserGroup = async (
  userGroupId: string,
  mutateUserToUserGroupDTO: MutateUserToUserGroupDTO,
): Promise<void> => {
  await userGroupApi.addUsersToUserGroup(userGroupId, mutateUserToUserGroupDTO);
};

export const removeUsersFromUserGroup = async (
  userGroupId: string,
  mutateUserToUserGroupDTO: MutateUserToUserGroupDTO,
): Promise<void> => {
  await userGroupApi.removeUsersFromUserGroup(userGroupId, mutateUserToUserGroupDTO);
};
