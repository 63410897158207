import { SiteDTO } from '@audioeye/mono-client';
import { useDynamicConfig, useFeatureGate } from '@statsig/react-bindings';
import { DynamicConfig, Gate } from 'types/util';

export type CanRenderA11yAdvisorData = {
  hasCustomFixes: boolean;
  hasManualAudits: boolean;
  hasEssentialOnly: boolean;
};

/**
 * Logic to determine whether or not to render the A11y Advisor
 * based on data from the site, the user, or if the user is a valid AHA user from statsig.
 * If they have no capabilities, we don't want to render anything
 * and instead take them back to the dashboard.
 *
 * Returns a mapping of key/value pairs on what service(s) they have enabled.
 * This mapping can be referred to as 'capabilities'.
 *
 * If they are unable to view the AHA, an empty object will be returned.
 */
export const useCanRenderA11yAdvisor = (site?: SiteDTO): CanRenderA11yAdvisorData => {
  const AHA_DEFAULTS: CanRenderA11yAdvisorData = {
    hasCustomFixes: false,
    hasManualAudits: false,
    hasEssentialOnly: false,
  };
  const { value: ahaAllSiteCapabilities } = useDynamicConfig(DynamicConfig.SiteAHACapabilities);
  let ahaSiteCapabilities: CanRenderA11yAdvisorData | undefined;
  if (site) {
    ahaSiteCapabilities = ahaAllSiteCapabilities[site.id] as CanRenderA11yAdvisorData;
  }
  const { value: ahaUserCapabilities } = useDynamicConfig(DynamicConfig.UserAHACapabilities);
  const { value: ahaUser } = useFeatureGate(Gate.A11yHealthAdvisor);

  if (ahaSiteCapabilities && Object.keys(ahaSiteCapabilities).length !== 0) {
    // Show AHA with settings from the Site
    // TODO PORTAL-3457: if this data came from an API, we wouldn't have to cast them
    // Ticket: https://audioeye.atlassian.net/browse/PORTAL-3457
    return ahaSiteCapabilities as CanRenderA11yAdvisorData;
  }

  // Site capabilities not found, check user capabilities
  if (ahaUserCapabilities && Object.keys(ahaUserCapabilities).length !== 0) {
    // Show AHA with settings from the User
    // TODO PORTAL-3457: if this data came from an API, we wouldn't have to cast them
    // Ticket: https://audioeye.atlassian.net/browse/PORTAL-3457
    return ahaUserCapabilities as CanRenderA11yAdvisorData;
  }

  // User Capabilities not found, check if a valid AHA user from statsig
  if (ahaUser) {
    // Show them the AHA with Advanced Compliance visible, but not active
    return AHA_DEFAULTS;
  }

  // User not enabled to see the AHA
  // TODO PORTAL-3457: if this data came from an API, we wouldn't have to cast them
  // Ticket: https://audioeye.atlassian.net/browse/PORTAL-3457
  return {} as CanRenderA11yAdvisorData;
};
