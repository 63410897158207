import {
  ChargebeeInvoicePaginatedResponseDTO,
  ChargebeeInvoiceQuery,
  EstimateDto,
  GetPlansResponseDto,
  MutateSubscriptionDTO,
  PlanApi,
  QuerySubscriptionPaginateEntitiesDTO,
  SubscriptionApi,
  SubscriptionDTO,
  SubscriptionPaginatedResponseDTO,
} from '@audioeye/mono-client';
import { CHARGEBEE_MOCK_PLANS } from 'util/mocks/CHARGEBEE_MOCK_PLANS';

import { APP_CONFIG } from '../../config';
import { client } from './client';

const planApi = new PlanApi(undefined, APP_CONFIG.api.url, client);
const subscriptionApi = new SubscriptionApi(undefined, APP_CONFIG.api.url, client);

export const getSubscriptionPlans = async (coupons: string[] = []): Promise<GetPlansResponseDto[]> => {
  // Return mock data for e2e. If we try to use `page.route` it will prevent page caching which slows down all tests
  if (process.env.GATSBY_ACTIVE_ENV === 'e2e') {
    return CHARGEBEE_MOCK_PLANS;
  }

  const result = await planApi.getPlans(coupons);

  return result.data;
};

export const getPlanEstimate = async ({
  id,
  siteId,
  coupon,
}: {
  id: string;
  siteId?: string;
  coupon?: string;
}): Promise<EstimateDto> => {
  const result = await planApi.getPlanEstimate(id, siteId, coupon);

  return result.data;
};

export const getSubscriptionById = async (subscriptionId: string): Promise<SubscriptionDTO> => {
  const result = await subscriptionApi.getSubscriptionById(subscriptionId);
  return result.data;
};

export const getSubscriptions = async ({
  filterBy,
  accountId,
  subBrandId,
  cursor,
  orderBy,
  sortDirection,
  pageSize,
}: QuerySubscriptionPaginateEntitiesDTO): Promise<SubscriptionPaginatedResponseDTO> => {
  const result = await subscriptionApi.getSubscriptions(
    filterBy,
    accountId,
    subBrandId,
    cursor,
    orderBy,
    sortDirection,
    pageSize,
  );
  return result.data;
};

export const getUserChargebeeInvoices = async ({
  subBrandId,
  pageSize,
  cursor,
}: ChargebeeInvoiceQuery & { subBrandId: string }): Promise<ChargebeeInvoicePaginatedResponseDTO> => {
  const result = await subscriptionApi.getUserChargebeeInvoices(subBrandId, pageSize, cursor);
  return result.data;
};

export const updateSubscription = async (id: string, dto: MutateSubscriptionDTO): Promise<SubscriptionDTO> => {
  const result = await subscriptionApi.updateSubscription(id, dto);
  return result.data;
};
