/**
 * Simple localStorage wrapper to abstract json conversion
 * and maintain type safety.
 */
import { AuthenticationSessionDTO } from '@audioeye/auth-client';
import { AuthenticationSessionDTO as MonoAuthenticationSessionDTO } from '@audioeye/mono-client';
import { StatsigUser } from '@statsig/js-client';
import { StatsigOverrideStore } from 'util/StatsigOverrideStore';

import { AnalyticsProperties } from '../analytics/types';

// Define any additional LS keys/types here
export interface LocalStorageConfig {
  // Set storage keys and associated types here:
  // NOTE: For the record I don't like that we're doing this in local storage and not session storage...
  disableAnalytics: boolean;
  storedRedirect: string;
  impersonating: boolean;
  app_user_id: string;
  aeye_auth_session: AuthenticationSessionDTO | MonoAuthenticationSessionDTO;
  statsig_user_data: StatsigUser | undefined;
  statsig_overrides: StatsigOverrideStore | undefined;
  sso_state_cache: string;
  ajs_anonymous_id: string;
  ajs_user_traits: AnalyticsProperties;
  show_install_banner: boolean;
  hide_a11y_report_banner: boolean;
  // Mapping of statsig feature keys to whether the feature has been dismissed
  featureDismissed: Record<string, boolean>;
  last_visited_site_id: string | undefined;
  recently_viewed_sites: { [key: string]: string[] };
}

/**
 * Get local storage value
 */
export const get = <K extends keyof LocalStorageConfig>(key: K) => {
  try {
    const data = typeof window !== 'undefined' && window.localStorage.getItem(key);

    if (data) {
      return JSON.parse(data) as LocalStorageConfig[typeof key];
    }
  } catch (error) {
    return null;
  }
};

/**
 * Set localstorage value
 */
export const set = (key: keyof LocalStorageConfig, data: LocalStorageConfig[typeof key]) => {
  try {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(key, JSON.stringify(data));
    }
  } catch (_) {
    /* no throw */
  }
};

/**
 * Remove local storage value
 */
export const remove = (key: keyof LocalStorageConfig) => {
  try {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem(key);
    }
  } catch (_) {
    /* no throw */
  }
};

/**
 * Set app_user_id into local storage
 */
export const setAppUserId = (userId: string | undefined): void => {
  if (!userId) {
    remove('app_user_id');
  } else {
    set('app_user_id', userId);
  }
};

/**
 * get app_user_id from local storage
 */
export const getAppUserId = (): string | null | undefined => get('app_user_id');

/**
 * remove app_user_id key from local storage
 */
export const removeAppUserId = (): false | void => remove('app_user_id');

/**
 * Set impersonating into local storage
 */
export const setImpersonating = (isImpersonating: boolean): void => {
  set('impersonating', isImpersonating);
};

/**
 * get impersonating from local storage
 */
export const getImpersonating = (): boolean | null | undefined => get('impersonating');

/**
 * remove impersonating key from local storage
 */
export const removeImpersonating = (): false | void => remove('impersonating');

export const getRecentlyViewedSites = (): { [key: string]: string[] } | null | undefined =>
  get('recently_viewed_sites');

export const setRecentlyViewedSites = (recentlyViewedSites: { [key: string]: string[] }) =>
  set('recently_viewed_sites', recentlyViewedSites);
