import {
  A11yRulesConfigLineageDTO,
  A11yRulesConfigStoredValueDTO,
  A11yRulesConfigValueWithRulesVersionDTO,
} from '@audioeye/mono-client';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  getAllRulesVersions,
  getCurrentEntityVersion,
  getCurrentGlobalVersion,
  getLineage,
  getResolvedReportingConfigForSite,
  updateCurrentEntityVersion,
  updateCurrentGlobalVersion,
} from '../../services/api/rules.api';

enum RulesCacheKeys {
  Current = 'current_rules_version',
  All = 'all_rules',
  Lineage = 'lineage',
}

export enum ReportingVersionType {
  Release = 'release',
  Shadow = 'shadow',
}

export const useGetCurrentGlobalRulesVersion = (): UseQueryResult<A11yRulesConfigStoredValueDTO, AxiosError> => {
  return useQuery({
    queryKey: [RulesCacheKeys.Current],
    queryFn: getCurrentGlobalVersion,
    retry: false,
  });
};

export const useGetCurrentEntityRulesVersion = (
  entityId: string,
): UseQueryResult<A11yRulesConfigStoredValueDTO, AxiosError> => {
  return useQuery({
    queryKey: [RulesCacheKeys.Current, entityId],
    queryFn: () => getCurrentEntityVersion(entityId),
    retry: false,
  });
};

export const useGetAllRulesVersions = (): UseQueryResult<any, AxiosError> => {
  return useQuery({
    queryKey: [RulesCacheKeys.All],
    queryFn: getAllRulesVersions,
    retry: false,
    select: (data) =>
      // Remove duplicate reportingVersion values, keeping the last (newest) occurance
      // Note: API returns the versions in descending order (oldest to newest)
      [
        ...data
          .reduce(
            (acc, item) => acc.set(item.reportingVersion, item),
            // using map (preserves ordering)
            new Map(),
          )
          .values(),
      ],
  });
};

export const useUpdateGlobalRules = (): UseMutationResult<void, AxiosError, A11yRulesConfigStoredValueDTO> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateCurrentGlobalVersion,
    onSuccess: async (token) => {
      await queryClient.setQueryData([RulesCacheKeys.Current], token);
    },
  });
};

export const useUpdateEntityRules = (
  entityId: string,
): UseMutationResult<void, AxiosError, A11yRulesConfigStoredValueDTO> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dto) => updateCurrentEntityVersion(entityId, dto),
    onSuccess: async (token) => {
      await queryClient.setQueryData([RulesCacheKeys.Current], token);
    },
  });
};

export const useGetReportingVersionLineageForEntity = (
  entityId: string | undefined,
): UseQueryResult<A11yRulesConfigLineageDTO, AxiosError> => {
  return useQuery({
    queryKey: [RulesCacheKeys.Lineage, entityId],
    queryFn: () => getLineage(entityId!),
    retry: false,
    enabled: !!entityId,
  });
};

export const useGetResolvedReportingConfigForSite = <TOut = A11yRulesConfigValueWithRulesVersionDTO>({
  siteId,
  reportShareId,
  select,
}: {
  siteId: string | undefined;
  reportShareId?: string;
  select?: (input: A11yRulesConfigValueWithRulesVersionDTO) => TOut;
}): UseQueryResult<TOut, AxiosError> => {
  return useQuery({
    queryKey: [RulesCacheKeys.Lineage, siteId, reportShareId],
    queryFn: () => getResolvedReportingConfigForSite(siteId!, reportShareId),
    retry: false,
    enabled: !!siteId,
    select,
  });
};
