import {
  MutateSiteCreationDefaultsDto,
  QuerySiteCreationDefaultPaginateEntitiesDTO,
  SiteConfig,
  SiteCreationDefaultApi,
  SiteCreationDefaultDTO,
  SiteCreationDefaultPaginatedResponseDTO,
} from '@audioeye/mono-client';

import { APP_CONFIG } from '../../config';
import { client } from './client';

const siteCreationDefaultApi = new SiteCreationDefaultApi(undefined, APP_CONFIG.api.url, client);

export const getSiteCreationDefaults = async ({
  filterBy,
  accountId,
  subBrandId,
  cursor,
  orderBy,
  sortDirection,
  pageSize,
}: QuerySiteCreationDefaultPaginateEntitiesDTO): Promise<SiteCreationDefaultPaginatedResponseDTO> => {
  const result = await siteCreationDefaultApi.getSiteCreationDefaults(
    filterBy,
    accountId,
    subBrandId,
    cursor,
    orderBy,
    sortDirection,
    pageSize,
  );

  return result.data;
};

export const getSiteCreationDefaultById = async (siteCreationDefaultId: string): Promise<SiteCreationDefaultDTO> => {
  const result = await siteCreationDefaultApi.getSiteCreationDefaultById(siteCreationDefaultId);

  return result.data;
};

export const createSiteCreationDefault = async (
  dto: MutateSiteCreationDefaultsDto,
): Promise<SiteCreationDefaultDTO> => {
  const result = await siteCreationDefaultApi.createSiteCreationDefault(dto);

  return result.data;
};

export const updateSiteCreationDefault = async (
  siteCreationDefaultId: string,
  dto: MutateSiteCreationDefaultsDto,
): Promise<SiteCreationDefaultDTO> => {
  const result = await siteCreationDefaultApi.updateSiteCreationDefault(siteCreationDefaultId, dto);

  return result.data;
};

export const updateSiteCreationDefaultSiteConfig = async (
  siteCreationDefaultId: string,
  dto: SiteConfig,
): Promise<SiteCreationDefaultDTO> => {
  const result = await siteCreationDefaultApi.updateSiteCreationDefaultSiteConfig(siteCreationDefaultId, dto);

  return result.data;
};

export const deleteSiteCreationDefault = async (siteCreationDefaultId: string): Promise<void> => {
  await siteCreationDefaultApi.deleteSiteCreationDefault(siteCreationDefaultId);
};
