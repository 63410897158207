import {
  AccountDTO,
  AccountPaginatedResponseDTO,
  MutateAccountDTO,
  PaymentDetailsDTO,
  QueryAccountPaginateEntitiesDTO,
} from '@audioeye/mono-client';
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  createAccount,
  findOneAccount,
  getAccountPaymentDetails,
  getAccounts,
  removeAccount,
  updateAccount,
} from '../services/api/account.api';

enum AccountCacheKeys {
  All = 'all_accounts',
  ByID = 'account_by_id',
  PaymentDetailsByID = 'payment_details_by_account_id',
}

export const useGetAccounts = (
  dto: QueryAccountPaginateEntitiesDTO,
  isQueryEnabled: boolean = true,
): UseInfiniteQueryResult<InfiniteData<AccountPaginatedResponseDTO>, AxiosError> =>
  useInfiniteQuery({
    queryKey: [AccountCacheKeys.All, dto],
    queryFn: ({ pageParam }) => getAccounts({ ...dto, cursor: pageParam == null ? undefined : pageParam }),
    getNextPageParam: (lastPage) => lastPage.afterCursor || undefined,
    enabled: isQueryEnabled,
    initialPageParam: '',
  });

export const useGetAccountPaymentDetails = (
  accountId: string | undefined | null,
): UseQueryResult<PaymentDetailsDTO, AxiosError> =>
  useQuery({
    queryKey: [AccountCacheKeys.PaymentDetailsByID, accountId],
    queryFn: () => getAccountPaymentDetails(accountId || ''),
    enabled: !!accountId,
  });

export const useGetAccountById = (accountId: string | undefined | null): UseQueryResult<AccountDTO, AxiosError> =>
  useQuery({
    queryKey: [AccountCacheKeys.ByID, accountId],
    queryFn: () => findOneAccount(accountId || ''),
    enabled: !!accountId,
  });

export const useCreateAccount = (): UseMutationResult<AccountDTO, AxiosError, MutateAccountDTO> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createAccount,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [AccountCacheKeys.All] });
    },
  });
};

export const useUpdateAccount = (): UseMutationResult<AccountDTO, AxiosError, MutateAccountDTO & { id: string }> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...dto }) => updateAccount(id, dto),
    onSuccess: async (_, { id }) => {
      await queryClient.invalidateQueries({ queryKey: [AccountCacheKeys.All] });
      await queryClient.invalidateQueries({ queryKey: [AccountCacheKeys.ByID, id] });
    },
  });
};

export const useDeleteAccount = (): UseMutationResult<void, AxiosError, { compartmentId: string }> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ compartmentId }) => removeAccount(compartmentId),
    onSuccess: async (_, { compartmentId }) => {
      await queryClient.invalidateQueries({ queryKey: [AccountCacheKeys.All] });
      await queryClient.invalidateQueries({ queryKey: [AccountCacheKeys.ByID, compartmentId] });
    },
  });
};
