import {
  CompartmentApi,
  CompartmentDTO,
  CompartmentPaginatedResponseDTO,
  QueryCompartmentPaginateEntitiesDTO,
} from '@audioeye/auth-client';

import { APP_CONFIG } from '../../../config';
import { client } from '../client';

const compartmentApi = new CompartmentApi(undefined, APP_CONFIG.auth.serviceUrl, client);

export const getCompartmentById = async (compartmentId: string): Promise<CompartmentDTO> => {
  const result = await compartmentApi.getCompartmentById(compartmentId);

  return result.data;
};

export const getCompartments = async (
  dto: QueryCompartmentPaginateEntitiesDTO,
): Promise<CompartmentPaginatedResponseDTO> => {
  const { filterBy, shouldOnlyReturnTenancies, parentCompartmentId, cursor, orderBy, sortDirection, pageSize } = dto;
  const result = await compartmentApi.getCompartments(
    filterBy,
    shouldOnlyReturnTenancies,
    parentCompartmentId,
    cursor,
    orderBy,
    sortDirection,
    pageSize,
  );

  return result.data;
};
