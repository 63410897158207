export enum ManagedSubscriptionPlanType {
  Simple = 'simple',
  Advanced = 'advanced',
  Commerce = 'commerce',
}

export enum SubscriptionPlanType {
  Free = 'free',
  Managed = 'managed',
}
