import { format as dateFns } from 'date-fns';

export enum DateFormat {
  Long = "MMM dd yyyy hh:mm aaaaa'm' xxx",
  Short = 'MMM do, yyyy',
  ShortDateYear = 'MMM d, yyyy',
  FullDateYear = 'MMM dd, yyyy',
  ShortFullMonth = 'MMMM d Y',
  ShortFullMonthWithComma = 'MMMM d, Y',
  Day = 'MMM do',
  SnakeShortDateYear = 'yyyy_MM_dd',
}

export enum TimeFormat {
  Hour = 'ha',
  HourMinute = 'h:mm a',
}

export const formatDate = (dt: string | number, format: string = DateFormat.Long) => dateFns(new Date(dt), format);

export const formatTime = (dt: string | number, format: string = TimeFormat.HourMinute) =>
  dateFns(new Date(dt), format);
