import { AuthenticationSessionDTO } from '@audioeye/auth-client';
import {
  HubspotExpertAuditRequestStatusDTO,
  MigrateUserToAccountDTO,
  ProvisionUserFromSSODTO,
  QueryUserPaginateEntitiesDTO,
  UpdateUserDto,
  UserApi,
  UserDTO,
  UserPaginatedResponseDTO,
  V2UserApi,
} from '@audioeye/mono-client';

import { APP_CONFIG } from '../../config';
import { client } from './client';

const userApi = new UserApi(undefined, APP_CONFIG.api.url, client);
const userApiV2 = new V2UserApi(undefined, APP_CONFIG.api.url, client);

export const getUserById = async (userId: string): Promise<UserDTO> => {
  const result = await userApiV2.getUser(userId);
  return result.data;
};

export const updateProfile = async ({ userId, data }: { userId: string; data: UpdateUserDto }): Promise<UserDTO> => {
  const result = await userApi.updateUser(userId, data);
  return result.data;
};

export const migrateUserToAccount = async (dto: MigrateUserToAccountDTO): Promise<void> => {
  await userApiV2.migrateUserToAccount(dto);
};

export const generateApiKey = async (userId: string): Promise<UserDTO> => {
  const result = await userApi.generateUserApiKey(userId);
  return result.data;
};

export const anonymizeUser = async (userId: string): Promise<void> => {
  const result = await userApi.adminMarkUserAsDeleted(userId);
  return result.data;
};

export const provisionUserFromSSO = async (
  dto: Omit<ProvisionUserFromSSODTO, 'payload'> & AuthenticationSessionDTO,
): Promise<void> => {
  await userApiV2.provisionUserFromSSO(dto as ProvisionUserFromSSODTO);
};

export const getUsers = async ({
  filterBy,
  accountId,
  cursor,
  orderBy,
  sortDirection,
  pageSize,
}: QueryUserPaginateEntitiesDTO): Promise<UserPaginatedResponseDTO> => {
  const result = await userApiV2.getUsers(filterBy, accountId, cursor, orderBy, sortDirection, pageSize);
  return result.data;
};

export const getHubspotExpertAuditRequestStatus = async (
  userId: string,
): Promise<HubspotExpertAuditRequestStatusDTO> => {
  const result = await userApi.getExpertAuditRequestStatus(userId);
  return result.data;
};
