import {
  ManualRemediationType,
  RulesRemediationDto,
  RulesRemediationExclusionDto,
  RulesRemediationFileDto,
  RulesRemediationTypesFileDto,
  SiteGroupType,
  SiteGroupVersion,
} from '@audioeye/mono-client';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import nullthrows from 'nullthrows';
import { rulesRemediationsApi, rulesRemediationsTypesApi } from 'services/api/ruleRemediations.api';

export type UseGetRuleRemediationsArs = {
  siteGroupId?: string;
  siteGroupType: SiteGroupType;
  reportingVersion?: string;
};

const OVERVIEW_QUERY_KEY = 'useGetSiteGroupOverview';

export const siteGroupOverviewKey = (
  siteGroupId: string | undefined,
  siteGroupVersion: SiteGroupVersion = SiteGroupVersion.WORKING,
) => [OVERVIEW_QUERY_KEY, siteGroupId, siteGroupVersion];

const getFilesKey = (siteGroupId: string | undefined, siteGroupVersion: SiteGroupVersion): (string | undefined)[] => [
  'rules_files',
  siteGroupId,
  siteGroupVersion,
];

export const useGetSiteGroupRulesOverview = ({
  siteGroupId,
  siteGroupVersion,
}: {
  siteGroupId: string | undefined;
  siteGroupVersion: SiteGroupVersion | undefined;
}): UseQueryResult<RulesRemediationDto[]> => {
  return useQuery({
    queryKey: siteGroupOverviewKey(siteGroupId, siteGroupVersion),
    queryFn: () =>
      rulesRemediationsApi
        .getOverviewForSiteGroup(nullthrows(siteGroupId), nullthrows(siteGroupVersion))
        .then((res) => res.data),
    enabled: siteGroupId != null && siteGroupVersion != null,
  });
};

export const useCreateExclusion = (): UseMutationResult<
  RulesRemediationExclusionDto,
  AxiosError,
  { siteGroupId: string; stableId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ siteGroupId, stableId }) =>
      rulesRemediationsApi.addExclusion(siteGroupId, stableId).then((axiosResponse) => axiosResponse.data),
    onSuccess: async (_, { siteGroupId }) => {
      await queryClient.invalidateQueries({
        queryKey: siteGroupOverviewKey(siteGroupId),
      });
      await queryClient.invalidateQueries({
        queryKey: getFilesKey(siteGroupId, SiteGroupVersion.WORKING),
      });
    },
  });
};

export const useDeleteExclusion = (): UseMutationResult<
  void,
  AxiosError,
  { siteGroupId: string; stableId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ siteGroupId, stableId }) =>
      rulesRemediationsApi.deleteExclusion(siteGroupId, stableId).then((axiosResponse) => axiosResponse.data),
    onSuccess: async (_, { siteGroupId }) => {
      await queryClient.invalidateQueries({
        queryKey: siteGroupOverviewKey(siteGroupId),
      });
      await queryClient.invalidateQueries({
        queryKey: getFilesKey(siteGroupId, SiteGroupVersion.WORKING),
      });
    },
  });
};
export const useCreateRulesRemediationFile = (): UseMutationResult<
  RulesRemediationFileDto,
  AxiosError,
  { siteGroupId: string; contents: string; name?: string; type?: ManualRemediationType; viewingSiteGroupId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ siteGroupId, contents, name, type = ManualRemediationType.RULE }) =>
      (await rulesRemediationsApi.addFile(siteGroupId, { name, type, contents })).data,
    onSuccess: async (_, { viewingSiteGroupId }) => {
      await queryClient.invalidateQueries({
        queryKey: siteGroupOverviewKey(viewingSiteGroupId),
      });
      await queryClient.invalidateQueries({
        queryKey: getFilesKey(viewingSiteGroupId, SiteGroupVersion.WORKING),
      });
    },
  });
};

export const useUpdateRulesRemediationFile = (): UseMutationResult<
  RulesRemediationFileDto,
  AxiosError,
  { name?: string; fileId: string; contents: string; viewingSiteGroupId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ fileId, name, contents }) =>
      (await rulesRemediationsApi.updateFile(fileId, { name, contents })).data,
    onSuccess: async (_, { viewingSiteGroupId }) => {
      await queryClient.invalidateQueries({
        queryKey: siteGroupOverviewKey(viewingSiteGroupId),
      });
      await queryClient.invalidateQueries({
        queryKey: getFilesKey(viewingSiteGroupId, SiteGroupVersion.WORKING),
      });
    },
  });
};

export const useGetFiles = (
  siteGroupId: string | undefined,
  siteGroupVersion: SiteGroupVersion = SiteGroupVersion.WORKING,
): UseQueryResult<RulesRemediationFileDto[]> =>
  useQuery({
    queryKey: getFilesKey(siteGroupId, siteGroupVersion),
    queryFn: async () => (await rulesRemediationsApi.getFiles(nullthrows(siteGroupId), siteGroupVersion)).data,
    enabled: siteGroupId != null,
  });

export const useRulesRemediationTypes = (
  siteGroupId: string | undefined,
): UseQueryResult<RulesRemediationTypesFileDto[]> =>
  useQuery({
    queryKey: ['rulesRemsTypes', siteGroupId],
    queryFn: async () => (await rulesRemediationsTypesApi.getTypesForSiteGroup(nullthrows(siteGroupId))).data,
    enabled: siteGroupId != null,
  });

export const useDeleteRemediationFile = (): UseMutationResult<
  void,
  AxiosError,
  { siteGroupId: string; fileId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ fileId }) => {
      await rulesRemediationsApi.deleteFile(fileId);
    },
    onSuccess: async (_, { siteGroupId }) => {
      await queryClient.invalidateQueries({
        queryKey: siteGroupOverviewKey(siteGroupId),
      });
      await queryClient.invalidateQueries({
        queryKey: getFilesKey(siteGroupId, SiteGroupVersion.WORKING),
      });
    },
  });
};
