import { useStatsigClient, useStatsigUser } from '@statsig/react-bindings';
import { useMemo } from 'react';

import { useAuth } from '../state/auth/useAuth';

type GateResult = {
  value: boolean;
  isLoading: boolean;
};

export const useGates = <T extends string>(
  gateNames: readonly T[],
  ignoreOverrides?: boolean,
  /**
   * Setting this to true will skip the gate check and return false
   * if the user is not logged in.
   */
  evaluateForLoggedInUsersOnly?: boolean,
): Record<T, GateResult> => {
  const { isLoggedIn } = useAuth();
  const { client } = useStatsigClient();
  const statsigUser = useStatsigUser();
  const initStarted = client.loadingStatus !== 'Uninitialized';
  const initialized = client.loadingStatus === 'Ready';

  return useMemo(
    () =>
      gateNames.reduce<Record<T, GateResult>>(
        (acc, gateName) => {
          const featureGate = (client as any)?._store?.[gateName] ?? { value: false };

          const value =
            initStarted && (evaluateForLoggedInUsersOnly ? isLoggedIn : true)
              ? ((ignoreOverrides && featureGate.value) || client?.checkGate(gateName)) ?? false
              : false;
          acc[gateName] = { value, isLoading: !initialized };
          return acc;
        },
        {} as Record<T, GateResult>,
      ),
    [gateNames, ignoreOverrides, initStarted, initialized, statsigUser.user],
  );
};
