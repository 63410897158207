import {
  AccountApi,
  AccountDTO,
  AccountPaginatedResponseDTO,
  MutateAccountDTO,
  PaymentDetailsDTO,
  QueryAccountPaginateEntitiesDTO,
} from '@audioeye/mono-client';

import { APP_CONFIG } from '../../config';
import { client } from './client';

const accountApi = new AccountApi(undefined, APP_CONFIG.api.url, client);

export const getAccounts = async ({
  filterBy,
  isManualBilling,
  cursor,
  orderBy,
  sortDirection,
  pageSize,
}: QueryAccountPaginateEntitiesDTO): Promise<AccountPaginatedResponseDTO> => {
  const result = await accountApi.getAccounts(filterBy, isManualBilling, cursor, orderBy, sortDirection, pageSize);

  return result.data;
};

export const findOneAccount = async (accountId: string): Promise<AccountDTO> => {
  const result = await accountApi.findOneAccount(accountId);
  return result.data;
};

export const getAccountPaymentDetails = async (accountId: string): Promise<PaymentDetailsDTO> => {
  const result = await accountApi.getAccountPaymentDetails(accountId);
  return result.data;
};

export const createAccount = async (dto: MutateAccountDTO): Promise<AccountDTO> => {
  const result = await accountApi.createAccount(dto);
  return result.data;
};

export const updateAccount = async (accountId: string, dto: MutateAccountDTO): Promise<AccountDTO> => {
  const result = await accountApi.updateAccount(accountId, dto);
  return result.data;
};

export const removeAccount = async (accountId: string): Promise<void> => {
  await accountApi.removeAccount(accountId);
};
