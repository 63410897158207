import {
  MutateSSOConfigurationDTO,
  QuerySSOConfigurationPaginateEntitiesDTO,
  SsoConfigApi,
  SSOConfigurationDTO,
  SSOConfigurationPaginatedResponseDTO,
  SSOConfigurationPublicDTO,
} from '@audioeye/auth-client';

import { APP_CONFIG } from '../../config';
import { client } from './client';

const ssoConfigApi = new SsoConfigApi(undefined, APP_CONFIG.auth.serviceUrl, client);
export const getSSOConfigurations = async ({
  filterBy,
  cursor,
  orderBy,
  sortDirection,
  pageSize,
}: QuerySSOConfigurationPaginateEntitiesDTO): Promise<SSOConfigurationPaginatedResponseDTO> => {
  const result = await ssoConfigApi.getSSOConfigurations(filterBy, cursor, orderBy, sortDirection, pageSize);

  return result.data;
};

export const getSSOConfigurationById = async (ssoConfigurationId: string): Promise<SSOConfigurationDTO> => {
  const result = await ssoConfigApi.getSSOConfigurationById(ssoConfigurationId);

  return result.data;
};

export const getPublicSSOConfigurationById = async (ssoConfigurationId: string): Promise<SSOConfigurationPublicDTO> => {
  const result = await ssoConfigApi.getPublicSSOConfigurationById(ssoConfigurationId);
  return result.data;
};

export const getSSOConfigurationsByDomain = async (domain: string): Promise<SSOConfigurationPublicDTO[]> => {
  const result = await ssoConfigApi.getSSOConfigurationsByDomain(domain);
  return result.data;
};

export const createSSOConfiguration = async (params: MutateSSOConfigurationDTO): Promise<SSOConfigurationDTO> => {
  const result = await ssoConfigApi.createSSOConfiguration(params);

  return result.data;
};

export const updateSSOConfiguration = async (
  ssoConfigurationId: string,
  params: MutateSSOConfigurationDTO,
): Promise<SSOConfigurationDTO> => {
  const result = await ssoConfigApi.updateSSOConfiguration(ssoConfigurationId, params);

  return result.data;
};

export const deleteSSOConfiguration = async (ssoConfigurationId: string): Promise<void> => {
  await ssoConfigApi.deleteSSOConfiguration(ssoConfigurationId);
};
