/* eslint-disable max-len */
import React from 'react';
import Helmet from 'react-helmet';

export function AudioEyeScript() {
  return (
    <Helmet>
      <script type="text/javascript">{`window.__AudioEyeSiteHash = '8714aca8c5892a8968ce75cc0bb2d8c5';`}</script>
      <script type="text/javascript" src="https://wsmcdn.audioeye.com/aem.js" />
    </Helmet>
  );
}
